.scene--img {
  width: 15.625rem;
  height: 22.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.description-breeze {
  font-size: 16px;
}

.fabrics {
  background-size: 180% 240% !important;
  background-position-x: -100px;
}

.chair {
  background-size: 100% 170% !important;
  background-position-y: -60px;
}

.samples {
  background-size: 490% 270% !important;
  background-position-y: -140px;
}

.vertical-space {
  height: 2rem;
}

.bg-breeze {
  background-color: $gray-lighter;
  flex-wrap: wrap;
}