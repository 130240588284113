@include media-breakpoint-down(lg) {
  .wrapper--cleaning-sm--general {
    height: 360px;
  }

  .title--cleaning-sm--general {
    font-size: 2rem;
  }

  .description--cleaning-sm--general {
    font-size: 1rem;
    width: 100%;
  }
}
