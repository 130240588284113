@include media-breakpoint-down(md) {
  .img-container {
    width: 72px;
    height: 72px;
  }

  .hover-icon {
    bottom: -42%;
    right: 4%;
  }
}
