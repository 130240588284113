.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.thumb {
  border-radius: 2;
  border: "1px solid #eaeaea";
  margin-bottom: 8;
  margin-right: 8;
  box-sizing: border-box;
}