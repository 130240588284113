.slide-container-project--segm-hotelery {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
}

.slide-img-project--segm-hotelery {
  display: block;
  width: 100%;
  height: 480px;
}

.slide-img-info-project--segm-hotelery {
  position: absolute;
  bottom: 0;
  padding: 1rem;
  background: rgba($gray-light, 0.25);
  backdrop-filter: blur(0.5rem);
  text-transform: capitalize;
  line-height: normal;
  color: $white;
}
