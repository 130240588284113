@include media-breakpoint-down(lg) {
  .slider__image-large-container {
    width: 60%;
    height: 280px;
  }

  .slider__container-samples-images {
    width: 40%;
  }

  .slider__sample-image {
    width: 120px;
    height: 72px;
    padding: 0 0.5rem;
  }

  .label-slider__sample-image {
    font-size: small;
  }
}
