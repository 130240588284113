@include media-breakpoint-up(md) {
  .slide-content__button {
    margin-top: 10px !important;
    font-size: large !important;
  }

  .slide-content__description {
    margin-bottom: 10px !important;
    font-size: large !important;
  }

  .home-slide {
    background-size: 130% 130% !important;
    height: 100vh !important;
  }
}

