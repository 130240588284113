.how-works-container {
  position: relative;
}

.box-hw {
  background-color: $anti-flash-white;
  color: $gray-light;
}

.spradling-marine-link {
  color: $gray-light;
  text-decoration: none;
}
