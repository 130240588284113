.info {
  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: $font-weight-light;
  width: 80%;
  text-wrap: balance;
}

.disclaimer {
  font-family: $font-family-base;
  font-size: 0.75rem;
  font-weight: $font-weight-light;
  font-style: italic;
}

.color-letter-rv {
  color: #777;
}

.product--information-uv-stability {
  display: initial;
}

.product--information-spacing {
  padding-bottom: 10px;
}
