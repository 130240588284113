.btn-sample {
  min-width: 240px;
  min-height: 50px;
  padding: 0.5rem 1.5rem;
  background-color: #58595b;
  color: $white;
  text-align: center;
  font-size: 1rem;
  font-family: $font-family-base;

  &:hover {
    color: $white;
  }

  .btn-label-sample--kizuna {
    margin: 0;
    font-size: 18px;
  }
}

.btn-sample-outline {
  background-color: transparent;
  color: $white;
  border: 1px solid $white;

  &:hover {
    color: $black;
    background-color: $white;
  }
}

.order-info-container--kizuna {
  position: fixed;
  top: 10%;
  right: 0;
  z-index: 10;

  width: 360px;
  max-height: 496px;
  background-color: $white;
  border: 1px solid $gray-light;
  padding: 1rem;

  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: transform 0.5s ease; /* Añadimos la transición aquí */

  .close-icon-container {
    position: fixed;
    margin: 0 1rem;
    right: 4%;

    .close-icon {
      color: $gray-light;
      cursor: pointer;
    }
  }

  .order-list {
    .sample-item-container {
      position: relative;
      margin: 1rem;

      &:not(:last-child)::after {
        content: ""; /* Necesario para crear el pseudo-elemento */
        display: block;
        height: 1px; /* Ajusta el grosor de la barra aquí */
        margin: 1.5rem 0;
        background-color: $gray-light; /* Color de la barra */
      }

      .sample-info-wrapper {
        display: flex;
        align-items: center;
      }

      .sample-color {
        width: 64px;
        height: 64px;
      }
    }
  }
}

.last-sample-item-add {
  margin: 1rem;
}

.slide-in {
  right: 4%;
}

.slide-out {
  transform: translateX(100%); /* Oculta el elemento */
}
