.accordion--kizuna {
  .item-accordion {
    border-bottom: 2px solid rgb(160, 160, 160);
    height: 30px;
    padding: 0 3rem;
  }

  .item-accordion-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(0, 0, 0);
    margin-bottom: 9px !important;
  }

  .button-accordion--kizuna {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    font-size: 20px;
    margin-bottom: 0px;
  }

  .button-accordion > h4 {
    margin-bottom: 0;
    padding: 0 !important;
  }

  .size-icon {
    width: 14px;
    height: 14px;
    font-weight: lighter;
  }

  .icon-button-more::after {
    content: "+";
    display: inline;
    color: grey;
    font-weight: bold;
    padding: 0;
    margin: 0;
    font-size: 40px;
    text-align: right;
  }

  .icon-button-minor::after {
    content: "-";
    display: inline;
    color: grey;
    font-weight: bold;
    padding: 0;
    margin: 0;
    font-size: 40px;
    text-align: right;
  }
}
