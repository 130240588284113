@include media-breakpoint-up(md) {
  .reference-size {
    font-size: 1rem;
  }

  .sample-image {
    height: 480px !important;
  }

  .glass-img-container {
    height: 100%;
  }

  .glass-img-container img {
    height: 100%;
    width: auto;
  }
}
