@include media-breakpoint-down(lg) {
  .product--information-detail-description {
    font-size: 0.875rem;
  }

  .product--information-detail-value {
    font-size: 0.813rem;
  }

  .product--information-detail--main-container {
    padding-block: 10px;
    padding-inline: 0px;
  }

  .disclaimer {
    font-size: 0.75rem;
  }

  .product--information-detail--main-container>table>tr {
    margin: 0;
    margin-bottom: .5rem;
    display: flex;
  }

  .info {
    width: 100%;
  }
}
