@include media-breakpoint-down(lg) {
  .btn-sample {
    min-height: 40px;

    .btn-label-sample--kizuna {
      margin: 0;
      font-size: 16px;
    }
  }
}
