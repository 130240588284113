@include media-breakpoint-down(md) {
  .slider-sections {
    .slick-list {
      .slick-track {
        .slick-slide {
          margin: 0.5rem 1.5rem;
        }
      }
    }
  }

  .img-application {
    width: 100%;
    height: 18rem;
  }
}
