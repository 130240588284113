@include media-breakpoint-down(sm) {
  .hero {
    height: 606px;
    overflow: hidden;
  }

  .hero-description-container {
    margin: 0 1rem;
    margin-bottom: 1.5rem;
    z-index: 1;

    .hero-kizuna-detail-sample-title {
      margin-bottom: 0.5;
    }

    .description-text {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .videoembed {
    width: 224%;
    height: 100%;
  }
}
