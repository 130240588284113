.step-form--group {
  font-family: $font-family-base;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 0.25rem;
}

.step-form--field {
  width: 100%;
  border: 0;
  border-bottom: 0.16rem solid $gray-700;
  outline: 0;
  font-size: 1rem;
  padding: 0.25rem 0;
  background: transparent;
  transition: border-color 0.25s;
  resize: none;

  &::placeholder {
    color: $text-muted;
  }

  // &:focus {
  //   border-bottom: 0.16rem solid $very-dark-cyan;
  // }
}

.step-form--label {
  display: block;
  font-size: 1.25rem;
  transition: color 0.25s;
}

// .step-form--field:focus + .step-form--label {
//   color: $very-dark-cyan;
// }

.step-form--group-check {
  display: flex;
}

%field-check {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.5rem 1rem;
  border-radius: 0rem;
  appearance: none;
  position: relative;
  border: solid 1px $gray-700;
  cursor: pointer;
}

%field-check-checked {
  content: "✓";
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: -0.063rem;
  left: -0.063rem;
  text-align: center;
  font-size: $font-size-sm;
  color: $white;
  background: $black;
  border: solid 1px $gray-700;
}

.step-form--field-check {
  @extend %field-check;
  &:checked::before {
    @extend %field-check-checked;
  }
}

.step-form--field-check--controlled {
  @extend %field-check;
}

.step-form--field-check--controlled-checked {
  &::before {
    @extend %field-check-checked;
  }
}

.label-item {
  display: block;
  font-size: $font-size-lg;
}

.description-item {
  font-size: $font-size-sm;
  color: $gray-700;
}
