.input-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 144px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: white;
  padding: 0.5rem;
}

.counter-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
}

.count {
  font-size: 18px;
}

.count-disabled {
  color: #a2a2a2;
}
