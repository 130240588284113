.main-container--cleaning-sm {
  height: 100vh !important;
}

.logo-container--cleaning-sm {
  display: flex;
  justify-content: center;
}

.title--cleaning-sm {
  text-align: center;
  color: $white;
}

.btns-container--cleaning-sm {
  display: flex;
  margin-top: 2.5rem;
}

.cleaning-guide--header_select_lang-main-page .css-319lph-ValueContainer {
  padding: 0;
}

.cleaning-guide--header_select_lang-main-page .css-1okebmr-indicatorSeparator {
  display: none;
}