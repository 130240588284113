.keep-in-mind-bg {
  position: relative;
  height: 20rem;
  overflow: hidden;
  background: url("../../../../../../Banner/img/banner.jpg") center/cover no-repeat;
}

.keep-in-mind-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.24);
}

.keep-in-mind-title {
  position: relative;
  z-index: 22;
}

.keep-in-mind-description {
  position: relative;
  top: 20px !important;
  left: 20%;
}

.how-works-container-mobile {
  position: relative;
}

.box-hw {
  background-color: $anti-flash-white;
  color: $gray-light;
}

.spradling-marine-link {
  color: $gray-light;
  text-decoration: none;
}
