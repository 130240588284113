@include media-breakpoint-up(xl) {
  .container-video-masiala {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .container-video-masiala > div {
    width: 100% !important;
    height: 595px !important;
  }

  .container-video-masiala > div > div {
    width: 100% !important;
    height: 100% !important;
  }
}
