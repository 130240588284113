@include media-breakpoint-up(lg) {
  .home-slide__form {
    padding-top: 45rem;
  }

  .slide-content__form__title {
    font-size: 4rem;
  }

  .slide-content__form__description {
    font-size: 1.5rem;
  }
}
