@include media-breakpoint-up(xxl) {
  .container-menu-masiala {
    padding-left: 130px;
    padding-right: 130px;
  }

  .logo-masiala img {
    width: 136px;
  }
}
