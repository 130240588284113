.footer-cleaning-guide {
  background-color: #777777;
}

.footer-cleaning-guide-mobile {
  background-color: white;
}

.font-color-footer {
  color: white;
}

.font-color-footer-mobile {
  color: #777777;
}

.logo-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-instance {
  width: 79%;
  height: auto;
  max-width: 100%;
}

.footer-sections {
  list-style: none;
}

.cleaning-sm-footer-link {
  text-decoration: none;
  color: $gray-light;
  color: white;

  &:hover {
    color: $gray;
  }
}

.cleaning-sm-footer-link-mobile {
  text-decoration: none;
  color: #777777;

  &:hover {
    color: $gray;
  }
}
