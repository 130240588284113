.main-header-cleaning-guide-marine {

  // @media (max-width: 990px) {
  //   .custom_class_header_mobile {
  //     display: flex;
  //   }
  // }

  .main-menu-sections {
    list-style: none;
    padding-left: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .main-menu-sections__section > div:after {
    background: #fff;
    content: "";
    display: block;
    height: $menu-underline-height;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  
  .main-menu-sections__section:hover > div:after {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: scaleX(1);
  }
  
  .header-main-menu {
    z-index: 1;
  }
  
  .header-menu-button {
    display: block;
    color: $white;
    background-color: transparent;
    border: none;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
  
    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      color: $white;
    }
  }
  
  .header-menu-button--active:after {
    background: #fff !important;
    content: "";
    display: block;
    height: $menu-underline-height;
    transform: scaleX(1);
  }
  
  .main-menu-cleaning-guide {
    overflow: visible !important;
  }
  
  .cleaning-guide--header_select_lang-header .css-1okebmr-indicatorSeparator {
    display: none;
  }
  
  .cleaning-guide--header_select_lang-header .css-1ie8n3h-option {
    background-color: #DEEBFF;
  }
  
  .cleaning-guide--header_select_lang-header  {
    display: inline-block;
  }
  
  .cleaning-guide--header_select_lang-header:hover {
    border: none;
  }
  
  .cleaning-guide--header_select_lang-header .css-1gtu0rj-indicatorContainer {
    padding-left: 0px;
  }
  
  .cleaning-guide--header_select_lang-header .css-10qsew5-menu {
    margin-top: 0px;
  }




}
