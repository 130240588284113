@include media-breakpoint-down(lg) {
  .header--kizuna {
    .navbar-light {
      .navbar-collapse {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-basis: auto;
      }

      .nav-link {
        font-size: 1rem;
      }

      .cart-icon {
        font-size: 1rem;
        margin: auto 0.5rem;

        .cart-img--bag {
          height: 18px;
        }

        .cart-count {
          right: 0;
        }
      }

      .dropdown-menu {
        position: absolute;
        text-align: center;
        background: rgba(107, 105, 107, 0.8);
      }
    }
  }
}
