.locations-map-section__finder-box {
  width: 100%;
}

.locations-map {
  height: 674px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
