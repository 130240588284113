@include media-breakpoint-up(lg) {
  
}

@media (min-width: 1100px) and (max-width: 1439px) {
  .container-fullviewport {
    height: 100vh;
  }
}

// WARNING: use this for large screens
@media (min-width: 1440px) {
  .container-fullviewport {
    height: 100vh;
  }
}
