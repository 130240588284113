@include media-breakpoint-up(lg) {
  .container--over-polymer {
    margin-left: 170px;
  }

  .container--backing-profile {
    max-width: 350px;
  }

  .sustainable-btn--masiala {
    font-size: 1.25rem;
  }

  .sustainable-title--masiala {
    border-bottom: 1px solid $timberwolf;
    margin: 1.8rem 0;
  }

  .img-sustainable-manufacturing--carbon-neutral {
    position: relative;
    top: 4px;
  }

  .img-sustainable-compilances--reach {
    position: relative;
    top: 4px;
  }
}
