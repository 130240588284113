.sample-image {
  height: 300px;
  border-radius: 30px;
}

.count-samples {
  width: fit-content;
  height: fit-content;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #00527a;
  border-radius: 15px !important;
}

.glass-magnifier {
  height: fit-content;
  border-radius: 30px;
}

.glass-img-container {
  height: 100%;
}

.glass-img-container img {
  height: 100%;
  width: auto;
}

.view-icon {
  z-index: 2;
}

.overflow-x {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  height: 240px !important;
}

#comment-box {
  background-color: #dee9ee;
  height: 60px;
  position: relative;
  padding: 20px;
  color: black;
}

#comment-box::after {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  bottom: 20px;
  left: 100%;
  background-color: #dee9ee;
  clip-path: polygon(100% 1%, 0 0, 0 100%);
}

.float-button {
  position: fixed;
  margin-bottom: 0;
  bottom: 0;
  right: 40px;
  z-index: 1;
}

.active-shape-lg {
  width: 100px;
  clip-path: polygon(100% 0, 0 0, 48% 100%);
  height: 50px;
}

.active-shape-sm {
  width: 50px;
  clip-path: polygon(74% 0, 29% 0, 49% 100%);
  height: 20px;
}

.active-shape-md {
  width: 100px;
  clip-path: polygon(74% 0, 29% 0, 49% 100%);
  height: 30px;
}

.reference-size {
  font-size: 0.75rem;
}

#main-add-sample-btn {
  // margin: 0;
  // padding: 0;
}
