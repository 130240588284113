.container--desc-sx {
  display: flex;
  height: fit-content;
}

.info--desc-sx {
  margin: 0 5rem;
}

.title--desc-rv {
  margin-bottom: 2rem;
  font-weight: bold;
  color: $gray-light;
}

.separator-rv {
  margin: 0 3.5rem;
  background-color: black;
  opacity: initial;
}

.btn--desc-sx {
  margin-top: 1rem;
  color: $white;
  background-color: $gray-light;

  &:hover {
    color: $white;
  }
}
