// Between the resolutions 992px and 1024px, occur some problems, that's why this media query
@media only screen and (min-width: 992px) and (max-width: 1180px) {
  .hero-description-container {
    margin: 0 2rem;
    margin-bottom: 3rem;
    z-index: 1;

    .description-text {
      font-size: $font-size-sm;
    }
  }

  .play-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px !important;
    height: 64px !important;
  }

  .play-icon-label {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }
}
