%flex-stack {
  display: flex !important;
  flex-wrap: wrap;
  overflow: hidden;
  width: inherit;
  transition: all 250ms linear;
}

.table-accordion--collapse {
  @extend %flex-stack;
  height: 0;
}

.table-accordion--collapse-show {
  @extend %flex-stack;
  height: fit-content;
  overflow: hidden;
}

.full-container {
  height: 100% !important;
}

.accordion--cleaning-sm--general {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  padding: 0.5rem 1rem;
  color: $white;
  cursor: pointer;
  position: relative;

  &::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.432);
  }

  svg {
    width: 16px;
    height: 16px;
    padding: 1rem;
    background-color: rgba($dark, 0.30);
    border-radius: 50%;
    z-index: 2;
  }
}
