@include media-breakpoint-down(md) {
  .sustainable-compilance-container-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sustainable-btn--masiala {
    font-size: 0.875rem !important;
  }
  
  .sustainable-row-certifications-imgs {
    width: 100%;
  }

  .sustainable-elements--aditiveFree-img {
    width: 85%;
  }
}
