.wrapper-banner--masiala {
  display: inline-block;
  position: relative;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.container-banner--masiala {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100vh;
  color: $white;
}

.title-banner--masiala {
  color: $white;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.description-banner--masiala {
  color: $white;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  strong {
    font-weight: 600;
  }
}

.btn-masiala {
  color: $white;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.75rem;
  border: 0.0625rem solid $white;
  aspect-ratio: 50/9;
  font-family: $font-family-base;

  &:hover {
    color: $white;
  }
}

.container--btn-masiala {
  padding: 6.081px 0px 6.919px 0px;
}

.container--banner-info--masiala {
  padding: 2rem 13rem;
  background-color: $reseda-green;
  text-align: center;
}

.banner-slider--masiala > .slick-next {
  position: absolute;
  right: 8%;
}

.banner-slider--masiala > .slick-prev {
  position: absolute;
  left: 8%;
  z-index: 2;
  transform: rotate(-180deg);
}

.slick-disabled {
  display: none !important;
}

.banner-slider--masiala > .slider-arrow {
  filter: grayscale(100%) brightness(1000%) !important;
}

.image-hiloft-rpet-banner {
  filter: grayscale(100) brightness(100);
}

.btn-masiala:hover {
  background-color: white;
  color: black;
}