.title--segm-hotelery {
  color: $gray-light;
  font-family: $font-family-base;
  z-index: 2;
}

.container-imgs--segm-hotelery {
  position: relative;
  display: flex;
  margin: 2rem 0;
}

.main-img {
  width: 65%;
  height: 384px;
}

.secondary-img {
  position: absolute;
  width: 45%;
  top: 40%;
  right: 5%;
}

.info--segm-hotelery {
  color: $gray-light;
  font-weight: lighter;
}

.info--segm-hotelery--technical-upholstery {
  width: 40%;
}
