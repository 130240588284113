@include media-breakpoint-down(md) {
  .video-catalog {
    width: 100%;
    margin-bottom: 1rem;
  }

  .video-installation {
    width: 100%;
  }
}
