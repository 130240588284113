.icon--segm-hotelery {
  width: 2.5rem;
  height: 2.5rem;
}

.icon-text--segm-hotelery {
  color: $gray-light;
  font-weight: lighter;
  font-size: small;
}
