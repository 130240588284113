@include media-breakpoint-down(md) {
  .section-video__description-valencia {
    font-size: 1.125rem;
    line-height: normal;
  }

  .footer-logo--masiala {
    width: 167px;
    height: 40px;
  }
}
