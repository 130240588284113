.wrapper--cleaning-sm--general {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100vh;
  background-position: center center;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.title--cleaning-sm--general {
  margin: 0;
  padding: 0;
  font-size: 3rem;
  color: $white;
  font-weight: bold;
  margin-bottom: 32px;
}

.description--cleaning-sm--general {
  width: 50%;
  font-size: 1.6rem;
  color: $white;
  margin: 0;
  font-weight: lighter;
}
