header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-static {
  position: absolute;
}

.bg-masiala-header {
  background-color: $reseda-green;
}

.logo-masiala {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  cursor: pointer;

  img {
    width: 64%;
  }
}
