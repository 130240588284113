@include media-breakpoint-down(lg) {

  .main-container-list-do-and-dont {
    .wrapper-list--cleaning-sm {
      justify-content: space-evenly;
      margin: 1rem 0;
    }
  
    .clean-img {
      width: 120px;
      margin: 0 auto;
    }
  
    .clean-label {
      font-size: 1.25rem !important;
      font-weight: 300 !important;
    }
  
    .disclaimer {
      line-height: 1.1rem;
    }
  }
}
