@include media-breakpoint-down(lg) {
  .how-works-container {
    display: block;
    text-align: center;
  }

  .box-hw {
    margin: 1rem 0;
    padding: 1.5rem;
    text-align: left;
  }
}
