@include media-breakpoint-up(lg) {
  .saxony--characteristic-main-icon {
    width: 105px;
    height: 100px;
  }

  .saxony--characteristic-single-icon {
    width: 23px !important;
    height: 22px !important;
  }

  .saxony--container-card-icon {
    width: 42px;
    height: 42px;
  }

  .saxony--card::after {
    content: "";
    display: block;
    height: 1px;
    width: 25px;
    margin-left: 8px;
    border-bottom: 2px solid gray;
    padding-bottom: 14px;
  }
}
