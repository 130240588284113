.ScrollDown {
  position: absolute;
  bottom: 0;
  height: 70px;
}

.ScrollDown button {
  margin: 0;
  padding: 0 !important;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.arrow {
  top: 85%;
  left: 50%;
  transform: translate(10%, -20%);
}

.arrow span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.045);
  border-right: 5px solid rgba(0, 0, 0, 0.045);
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}