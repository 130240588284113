input.form-control,
select.form-control,
textarea.form-control {
  &:focus {
    border-color: gray;
    box-shadow: none !important;
  }

  font-family: "Gill Sans", sans-serif;
  font-size: 20px;
  font-weight: lighter;
  letter-spacing: 1px;
  border: 2px solid #bdbdbd;

  &::placeholder {
    color: gray !important;
    font-family: "Gill Sans", sans-serif;
    font-weight: lighter;
    font-size: 20px;
    letter-spacing: 1px;
  }
}

.select-color {
  letter-spacing: 1px;
}

.footer-form-container {
  height: 50px !important;
}

.sampling-request-modal--kizuna {
  color: gray;

  h3 {
    font-family: "Dala Moa", sans-serif;
    color: gray !important;
  }

  h4 {
    font-family: "Dala Moa", sans-serif;
    color: gray !important;
  }
}
