.container-card--masiala {
  position: relative;
  width: 78px;
  height: 128px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.actived-card--masiala {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 24px 32px;
    border-color: transparent transparent #5f7052 transparent;
  }
}

.img-card--masiala {
  width: 100%;
  height: 72px;
}

.card-container-info--masiala {
  padding: 0.25rem;

  p {
    margin: 0;
  }
}

.card-label--masiala {
  text-transform: uppercase;
  color: $dim-gray;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.card-stock--masiala {
  color: $dim-gray;
  font-size: $small-font-size;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.14px;
}

