@include media-breakpoint-up(md) {
  .locations-map-section {
    position: relative;
    height: 674px; // must be the same as in the mapElement prop for the AsyncGoogleMaps component
    background-color: white;
  }

  .locations-map {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .locations-map-section__finder-box {
    width: 500px;
    position: absolute;
    z-index: 100;
    padding: 2rem 1.5rem 0.5rem;
    margin: 4rem 0 0 1.5rem;
  }
}
