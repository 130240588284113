.img-container {
  display: block;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: all 0.55s ease;

  &:hover {
    transform: translateY(-1rem);
    box-shadow: 0 2px 12px #a3b2c2;
  }
}

.img-size {
  border-radius: 0.5rem;
}

.benefit-label {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
