@include media-breakpoint-down(md) {
  .hero-description-container {
    margin: 0 1rem;
    margin-bottom: 2rem;
    z-index: 1;

    .hero-kizuna-detail-sample-title {
      margin-bottom: 0.5rem;
    }

    .description-text {
      width: 90%;
    }
  }

  .play-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px !important;
    height: 64px !important;
  }

  .play-icon-label {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }
}
