.individual-img {
  height: 320px;
}

// mockey patch for styles of react-slick
.slick-slide:first-child, .slick-active {
  z-index: 10;
}

.slider-sections--segm-hotelery {
  display: flex !important;
  color: $gray-light;

  .slick-list {
    flex: 1;

    .slick-track {
      display: flex;
      justify-content: space-between;

      .slick-slide {
        width: auto !important;
        margin: 0.5rem 1rem;
        text-align: center;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          background: $silver;
          height: 0.15rem;
        }

        &:hover::after {
          background: $very-dark-cyan;
        }
      }

      .slick-current {
        &::after {
          content: "";
          background: $very-dark-cyan;
          display: block;
          height: 0.15rem;
          transform: scaleX(1);
        }
      }
      
    }
  }
}
