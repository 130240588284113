@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@include media-breakpoint-down(sm) {
  .home-slide__form-vestuario {
    background-position: initial;
    background-size: cover;
  }
}
