@include media-breakpoint-down(md) {
  .how-works-container-mobile {
    display: block;
    text-align: center;
  }

  .box-hw {
    margin: 1rem 0;
    padding: 1.5rem;
    text-align: left;
  }

  .keep-in-mind-description {
    width: 80%;
  }

  .keep-in-mind-description {
    position: relative;
    top: 20px !important;
    left: 10%;
  }

  .how-works-container-mobile {
    position: relative;
  }

  .subtitle-keep-in-mind{
    width: 100% !important;
  }

}
