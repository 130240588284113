@include media-breakpoint-down(lg) {
  .info-cleaning-produre {
    padding: 0;
    display: flex;
    align-items: start;
  }

  .number-cleaning-produre {
    margin-right: 1.5rem;
    font-size: 3rem;
    font-weight: bold;
    width: 20px;
  }

  .number-cleaning-produre:first-child {
    margin-right: 2rem;
  }

  .process-cleaning-produre {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}
