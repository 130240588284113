.hero-container {
  background-position: center;
  background-size: cover;
  color: $white;
}

.container-in-some-steps {
  height: 100vh;
}

.hero-title {
  text-align: center;
  text-transform: capitalize;
}

.hero-description {
  br {
    content: "";
    display: block;
    height: 0.25rem;
  }
}

.move-background {
  background-size: 150% 130%;
  background-position-y: 0px;
  background-repeat: no-repeat;
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container {
  animation: animate 0.5s;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translateY(24rem) scale(0.8);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
