@include media-breakpoint-up(lg) {
  .accordion {
    padding-inline: 40px;
  }

  .item-accordion {
    padding-left: 40px;
    padding-right: 40px;
  }

  .item-accordion-title {
    font-size: 1.25rem;
  }
}
