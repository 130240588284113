.application-container {
  margin: 0 1.25rem;
  text-align: center;
}

.application-img--masiala {
  height: 24px;
  color: #999999;
}

.description-feature {
  font-size: 0.75rem;
  text-transform: capitalize;
  margin: 0.5rem 0;
  line-height: normal;
}
