@include media-breakpoint-down(lg) {
  .container-menu-masiala {
    padding-left: 10px;
    padding-right: 10px;
  }

  .logo-masiala {
    img {
      width: 64%;
      width: 68%;
    }
  }

  .header-main-menu {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .main-menu-sections {
    margin: 0;
    @include clearfix();
    display: block;

    &:last-child {
      float: none;
    }
  }

  .header-main-menu--open {
    display: block;
  }

  .main-menu-sections {
    display: block;
    max-height: 0;
    overflow: hidden;
    background-color: $reseda-green;
    transition: all 0.3s ease-out;
  }

  .main-menu-sections__section {
    display: block;
  }

  .main-menu-sections--open {
    max-height: 16rem;
  }

  .header-menu-button {
    height: $header-menu-button-height;
    line-height: $header-menu-button-height;
    margin: 0.5rem 1rem;
  }
}
