.wrapper-samples-details--masiala {
  padding: 0 $spacer * 1.5;
}

.title-samples-details--masiala {
  color: $slate-gray;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1.5rem 0rem;
}

.container-search-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.container-master-sample {
  position: relative;
}

.large-img--masiala {
  border-radius: 1rem;
  max-height: 358px !important;
}

.sample-detail-selected--masiala {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 0.25rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: rgba(108, 117, 125, 0.6);
  color: $white;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.btn-request-add-sample-masiala,
.btn-request-catalogue-masiala {
  margin-top: 49px;
  color: $slate-gray;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px !important;
  border: 0.0625rem solid $slate-gray;
  height: 52px;
  max-width: fit-content;
  width: 100%;

  &:hover {
    color: $white;
    background-color: $slate-gray;
  }
}
