.container-op {
  font-family: $font-family-base;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.description-op {
  margin-top: -0.5rem;
}
