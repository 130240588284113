@include media-breakpoint-up(md) {
  .video-catalog {
    width: 50%;
    margin-bottom: 1.5rem;
  }

  .video-installation {
    width: 100%;
  }
}
