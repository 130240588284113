.main-container-products-home-quickship-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f6fb76;
}

.home--products-container {
  height: fit-content;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  position: relative;

  ::-webkit-scrollbar {
    width: 0px;
  }
}

.downArrowDesktop-quickshippage {
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
}

.home--products-container-product {
  height: 100vh;
  scroll-snap-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  width: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  section {
    position: absolute;

    h1 {
      color: #fff;
      text-align: center;
      font-family: "Gill Sans";
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .btn--Products-home-quickship {
      color: #fff;
      text-align: center;
      font-family: "Gill Sans";
      font-size: 16px;
      font-weight: 400;
      width: 210px;
      height: 52px;
      background-color: #000000;
      padding: 1.25rem;
      border-radius: 1.25rem;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }

  .home--products-container-arrow-down {
    display: none;
  }
}
