%border-radious {
  border-radius: 1rem;
}

.container-reference {
  // @extend %border-radious;
  cursor: pointer;
  // box-shadow: 0px 0px 8px 0px rgba($dark, 0.16);
  transition: all 0.55s ease;

  &:hover {
    transform: translateY(-1rem);
  }
}

.img-reference {
  height: 64%;
}
