@include media-breakpoint-down(sm) {
  .scene--img {
    width: 7.8125rem;
    height: 11.25rem;
  }

  .vertical-space {
    height: 0;
  }
}
