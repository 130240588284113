.footer--masiala {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: rgb(194, 194, 194, 0.5);
  height: 96px;
  flex-shrink: 0;
}

.section-video__description-valencia {
  text-wrap: balance;
  font-weight: lighter;
  font-size: 1rem;
  line-height: 1.789rem;
  color: #6c757d;
  margin: 2rem 0;
}

.section-video__description-relevant-wrd {
  color: #5f7052;
}