.scroll-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroll-box__wrapper {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  overflow-x: auto;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-box__wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-box__container {
  height: 100%;
  width: 100%;
  display: inline-flex;
}

.scroll-box__container .scroll-box__item:first-child {
  padding-left: 0;
}

.scroll-box__container .scroll-box__item:last-child {
  padding-right: 0;
}

.scroll-box__item {
  display: flex;
  width: fit-content;
  border-radius: 1rem;
}

.scroll-arrow {
  z-index: 5;
  position: absolute;
  cursor: pointer;
  color: $gray-400;
  top: 50%;
  border-radius: 50%;
  box-shadow: 0 0 1rem 0.25rem rgba($dark, 0.2);
}

.arrow-left {
  left: 0.5rem;
}

.arrow-right {
  right: 0.5rem;
}
