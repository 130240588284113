.font-gray-color {
  color: $gray-light;
}

.saxony--section-container {
  height: 380px;
}

.saxony--container-main-icon {
  height: 130px;
}

.saxony--characteristic-title {
  font-weight: 600;
}

.saxony--container-icons {
  height: 80px;
}

.saxony--container-card-icon {
  width: 64px;
  padding: 0.5rem;
  box-shadow: 2px 2px 8px 2px #0000000d;
  height: 64px;
  cursor: pointer;
}

.saxony--card::after {
  content: "";
  display: block;
  height: 1px;
  width: 50px;
  margin-left: 8px;
  border-bottom: 2px solid gray;
  padding-bottom: 14px;
}

.saxony--section-adicional-info {
  height: 90px;
}

.saxony--characteristic-description,
.saxony--section-paragraph,
.saxony--section-adicional-info,
.saxony--environment {
  font-size: 1.125rem;
  line-height: normal;
}
