.item-accordion {
  padding: 0;
  border-bottom: 2px solid rgb(160, 160, 160);
  height: 35px;
}

.item-accordion-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.collapse:not(.show) {
  display: none !important;
}

.collapse {
  height: fit-content !important;
  padding: 0;
  margin: 0;
  width: 100% !important;
  display: block !important;
  overflow: visible;
}

.button-accordion {
  width: 100%;
  outline: none;
  border: none;
  font-size: 18px;
  height: 20px;
}

.size-icon-masiala {
  width: 17px !important;
  height: 17px !important;
  font-weight: lighter;
}

.icon-button-more::after {
  content: "+";
  display: inline;
  color: grey;
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: right;
}

.icon-button-minor::after {
  content: "-";
  display: inline;
  color: grey;
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: right;
}
