.bg-contact {
  margin-top: 6rem;
  background-color: rgba($gunmetal, 0.8);
}

.slide-content__button {
  margin-top: 0px !important;
  font-size: x-small !important;
}

.slide-content__description {
  margin-bottom: 10px !important;
  font-size: small !important;
}

.slide-content--center {
  top: 43% !important;
}

.home-slide {
  background-size: 210% 130%;
  height: 470px !important;
}

.slide-content__title,
.slide-content__description {
  color: $slate-gray;
}

// FIX THE PROBLEM OF DAMAGE STYLES BETWEEN IN OTHERS LANDINGS
.slide-content__button {
  background-color: $slate-gray !important;
  color: white !important;
}

.home-slide::after {
  background-color: rgba(255, 255, 255, 0) !important;
}

.bg-form {
  background-color: $gunmetal;
}
