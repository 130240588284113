@include media-breakpoint-down(md) {
  .img-container {
    margin: 1rem 0;
  }

  .img-size {
    width: 100%;
    height: 16rem;
  }
}
