@include media-breakpoint-down(md) {
  .sustainable-compilance-container-img {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 79px;
    // height: 60px;
  }

  .sustainable-btn--masiala {
    font-size: 0.875rem !important;
  }
  
}
