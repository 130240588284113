footer {
  background-color: $black;
}

.logo-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-saxony--spradling-img {
  width: 50% !important;
}

.logo-instance-lonely {
  width: 12%;
  max-width: 100%;
}

.copyright-label {
  position: absolute;
  bottom: 0;
  display: block;
  font-size: calc($font-size-xs * 0.85);
  text-transform: uppercase;
}

.footer-sections {
  list-style: none;
}

.label {
  font-family: $font-family-title;
}

.social-media-container {
  float: right;
}

.social-media-icon {
  margin: 0 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
