@include media-breakpoint-down(md) {
  .wrapper-list--cleaning-sm {
    justify-content: space-evenly;
    margin: 1rem 0;
  }

  .disclaimer {
    font-size: .6rem;
    font-style: italic;
    margin: 0;
    color: #6b6b6b !important;
  }

  .clean-img {
    width: 100px !important;
    margin: 0 auto;
  }

  .clean-label {
    font-size: 1rem !important;
  }
}
