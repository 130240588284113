@include media-breakpoint-down(lg) {
  .how-works-container-mobile {
    display: block;
    text-align: center;
  }

  .box-hw {
    margin: 1rem 0;
    padding: 1.5rem;
    text-align: left;
  }

  .how-works-container-mobile {
    position: relative;
    max-height: fit-content;
  }

  .subtitle-keep-in-mind{
    width: 75%;
  }
}
