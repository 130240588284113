.wrapper-cleaning-produre {
  background-color: $anti-flash-white;
}

.container-cleaning-produre {
  color: $gray-light;
}

.info-cleaning-produre {
  display: flex;
  padding: 0 1rem;
}

.number-cleaning-produre {
  margin-right: 0.5rem;
  font-family: $font-family-title;
  font-size: 6rem;
  line-height: 1.5rem;
}

.process-cleaning-produre {
  white-space: pre-line;
  width: 236.516px;
  font-weight: lighter;
  font-size: 1.3rem;
  line-height: normal;
}
