@include media-breakpoint-up(md) {
  .home-slide__form {
    padding-top: 50rem;
  }

  .home-slide__form__content {
    width: 100%;
    height: auto;
    position: absolute;
  }

  .home-slide__form__content--left {
    left: 0;
    bottom: 0;
    padding-bottom: 6rem;
  }

  .home-slide__form__content--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide-content__title {
    font-size: $h1-font-size * 1.5;
  }
}
