@media (min-width: 400px) and (max-width: 767px) {
  .container-video-masiala {
    position: relative;
    width: 100%;
    height: 300px !important;
  }

  .container-video-masiala > div {
    width: 100% !important;
    height: 300px !important;
  }

  .container-video-masiala > div > div {
    width: 100% !important;
    height: 100% !important;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .container-video-masiala {
    position: relative;
    width: 100%;
    height: 500px !important;
  }

  .container-video-masiala > div {
    width: 100% !important;
    height: 500px !important;
    overflow: hidden;
  }

  .container-video-masiala > div > div {
    width: 100% !important;
    height: 100% !important;
  }

  .poster-masiala {
    height: 130%;
  }
}


@media (min-width: 1200px) and (max-width: 1400px) {
  .container-video-masiala {
    position: relative;
    width: 100%;
    height: 600px !important;
  }

  .container-video-masiala > div {
    width: 100% !important;
    height: 600px !important;
    overflow: hidden;
  }

  .container-video-masiala > div > div {
    width: 100% !important;
    height: 100% !important;
  }

  .poster-masiala {
    height: 130%;
  }
}

@media (min-width: 1700px) and (max-width: 1999px) {

  .container-video-masiala,
  .container-video-masiala > div {
    height: 700px !important;
  }
}

@media (min-width: 2000px) {

  .container-video-masiala,
  .container-video-masiala > div {
    height: 800px !important;
  }
}

@media (min-width: 2300px) {

  .container-video-masiala,
  .container-video-masiala > div {
    height: 950px !important;
  }
}
