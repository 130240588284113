.header-background--rv {
  background-color: $gray-light !important;
}

.footer-background--rv {
  background-color: $gray-light !important;

  .footer-sections {
    width: fit-content;
    margin-left: auto;
  }
}
