@include media-breakpoint-only(md) {
  .container-card--masiala {
    width: 300px !important;
    height: 200px !important;
    margin-bottom: .75rem;
  }

  .img-card--masiala {
    height: 150px !important;
  }
}
