.container-video-kizuna {
  position: relative;
  width: 100%;
  height: 630px;
  margin-top: 1rem;

  .container-poster {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    aspect-ratio: 16/9;

    .poster {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    .btn-play-kizuna {
      position: absolute;
      color: $gray-300;
      border-color: $gray-300;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
    }
  }
}

.container-video-kizuna > div {
  width: 100% !important;
  height: 630px;
  overflow: hidden;
}

.container-video-kizuna > div > div {
  width: 100% !important;
  height: 100% !important;
}
