.container-media {
  background-color: $gray-100;
}

// mockey patch for styles of react-slick
.slider-sections--petfriendly {
  display: flex !important;

  .slick-list {
    flex: 1;

    .slick-track {
      display: flex;
      flex-wrap: wrap !important;
      justify-content: flex-start;
      flex-direction: column;
      height: auto !important;

      .slick-slide {
        width: auto !important;
        margin: 0.5rem 0;
        text-align: start;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          background: $silver;
          height: 0.15rem;
          max-width: 20rem;
        }

        &:hover::after {
          background: $very-dark-cyan;
        }
      }

      .slick-current {
        &::after {
          content: "";
          background: $very-dark-cyan;
          display: block;
          height: 0.15rem;
          transform: scaleX(1);
          max-width: 20rem;
        }
      }
    }
  }
}
