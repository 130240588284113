.fill-remaining-space {
  flex: 1;
}

.wrapper-samples-details--masiala {
  padding: 0 $spacer * 1.5;
}

.title-samples-details--masiala {
  color: #6c757d;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1.5rem 0rem;
}

.container-search-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.rounded-circle svg {
  width: 36px !important;
  height: 36px !important;
}

.container-master-sample-quickship {
  position: relative;
  max-width: 100%;

  .container-sample-quickship-param {
    max-width: 100%;

  }
}

.large-img--quickship {
  border-radius: 1rem;
  aspect-ratio: 15/11;

  img {
    min-height: 100%;
    width: 100%;
  }
}

.sample-detail-selected--quickship {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 0.25rem 1rem;
  border-radius: 0 0 1rem 1rem;

  background-color: rgba(108, 117, 125, 0.6);

  color: #FFF;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.btn-request-add-sample-masiala,
.btn-request-catalogue-masiala {
  margin-top: 49px;
  color: #6c757d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px !important;
  border: 0.0625rem solid #6c757d;
  height: 52px;
  max-width: 355px;
  width: 100%;
  font-family: 16px;

  &:hover {
    color: #FFF;
    background-color: #6c757d;
  }
}

.btn-request-width-de {
  max-width: 424px;
}


.main-page-slug-quickship {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 0;
}

.main-container-content-page-quickship-collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 76px;
  max-width: 1280px;
  padding: 0 15px 2rem 15px;

  @media (max-width: 768px) {
    padding: 0;
    overflow: hidden;
  }
}

.container-row-page-quickship-collection {
  width: 100%;
  max-width: 1280px;
  margin-top: 20px;
  display: flex;
  gap: 1rem;
  padding: 0 15px; 
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;

  }
}

.container-row-quickhip-selector {
  max-width: 100%;
  width: 100%;
}


.Container-selects-quickship-page {
  display: flex;
  gap: 0.5em;
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  @media (max-width: 600px) {
    padding-bottom: 0.5rem;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
  }
}

.container-row-selector-collection-quiickship-page {
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #98989A;
  padding: 1rem 0;
  margin-top: 1rem;
  padding: 0 15px;

  .text-selector-color-quickship-page {
    color: #98989A;
    font-family: "Gill Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    padding-right: 1rem;
    border-right: 1px solid #98989A;

    @media (max-width: 768px) {
      font-size: 26px;
      margin-top: 0rem;
    }
  }

  div[class*="indicatorContainer"]{
    svg {
      width: 30px !important;
      height: 30px !important;
    }
  }

  div[class*="control"],
  div[class*="input"],
  div[class*="singleValue"],
  div[class*="ValueContainer"] {
    align-items: baseline;
    color: #000 !important;
    font-family: "Gill Sans" !important;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    padding: 2px 15px;

    div[class*="container"] {
      width: 100%;
    }
    
    div[data-value]  {
      font-size: 26px !important;
    }
    div[class*="control"],
    div[class*="input"],
    div[class*="singleValue"],
    div[class*="ValueContainer"] {
      max-width: 100%;
      font-size: 26px !important;
      align-items: baseline;
    }

    div[class*="indicatorContainer"]{
      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }

  }
}

.container-sample-list-and-quantity {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 5rem;

  @media (max-width: 600px) {
    padding-bottom: 4rem;
    margin-bottom: 2rem !important;
  }

  .quantity-selector-quickship-page {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 15px;
    
    @media (max-width: 600px) {
      justify-content: space-between;
    }

    .container-quantity {
      position: relative;
      // overflow: hidden;
      max-width: 144px;
      height: 100%;

      @media (max-width: 768px) {
        padding: 0;
      }

      .limit-reached-message-quickship {
        position: absolute;
        top: -110%;
        left: 0;
        z-index: 100;
        width: 300px;
        background-color: #D9D9D9;
        color: #606060;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        transition: all 0.3s ease;

        span {
          font-weight: 600;
        }

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 30%;
          border-width: 10px;
          border-style: solid;
          border-color: #D9D9D9 transparent transparent transparent;
        }
      }

      .quantity-input {
        background-color: red;
        padding: 10px 20px 10px 16px;
        text-align: center;
        outline: none;
        border-radius: 20px;
        border: 1px solid #999;
        background: #FFF;
        min-height: 40px;
        max-width: 100%;
        transition: all 0.3s ease;
        pointer-events: none;

        color: #000;
        font-family: "Gill Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        overflow: hidden;
      }

      .quantity-btn-increment, .quantity-btn-decrement {
        border: none;
        outline: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s ease;
      }

      .quantity-btn-increment {
        position: absolute;
        top: 0;
        right: 10px;
        height: 20px;

        &:active {
          transform: translateY(-2px);
        }
      }

      .quantity-btn-decrement {
        position: absolute;
        bottom: 0;
        right: 10px;
        height: 20px;

        &:active {
          transform: translateY(2px);
        }
      }
    }

    .btn-add-sample {
      border: none;
      outline: none;
      border-radius: 20px;
      background: #00575F;
      padding: 10px 16px;

      color: #FFF;
      text-align: center;
      font-family: "Gill Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      
      &:hover {
        box-shadow: 0px 0px 10px #00575f83;
      }
      
      @media (max-width: 600px) {
        width: 100%;
        padding: 10px 0px;
      }
  }
}
}




.modal-request-form-quickship-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0000007e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
  height: 100%;
  margin: 0;

  .content-request-form-quickship-page {
    background-color: #ffffff;
    padding: 2.5rem 3rem;
    border-radius: 10px;
    width: clamp(360px, 1100px, 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;

    h3 {
      color: var(--gris-lineas-oscuro, #6C757D);
      text-align: center;
      font-family: "Dala Moa";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.5rem;
    }

    p {
      color: var(--gris-lineas-oscuro, #6C757D);
      font-family: 'Gill Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    .text-header-request-form-quickship {
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .close-modal-form-request-quickship {
      color: #6C757D;
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: 500;
      font-family: 'Gill Sans';
      font-size: 25px;
      cursor: pointer;
      padding: 0.5rem;
    }

    .btn-submit-request-form-quickship {
      border-radius: 6px;
      background: #606060;
      color: #ffffff;
      width: 160px;
      height: 40px;
      flex-shrink: 0;
      border: none;
      outline-color: transparent;
      outline: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #7a7a7a;
      }
    }
    
    @media (max-width: 768px) {
      overflow-y: scroll;
      max-height: 100%;
      border-radius: 0px;
      justify-content: flex-start;
      padding: 3rem 1rem;

        h3 {
          font-size: 30px;
        }
      }
    }
  }

.form-request-quickshippage {
  width: 100%;
  font-family: 'Gill Sans';


  .row-request-form-quickship {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .col-request-form-quickship {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label-request-form-quickship {
    // font-weight: bold;
    color: #606060 !important;
    font-family: 'Gill Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form-control {
    border: 1px solid #BDBDBD;
    box-shadow: none !important;
  }

  .label-request-form-quickship{
    color: #606060;
    font-family: 'Gill Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form-control:focus {
    border: 1px solid #BDBDBD !important;
  }

  .form-control.is-invalid {
    background-image: none !important;
    border-color: gray;
  }

  .form-check-label {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 5px;
  }
}