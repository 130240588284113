.desc--vid-rv {
  width: 70%;
  color: $gray;
  font-size: $h1-font-size;
  font-family: $font-family-base;
  font-weight: 300;
}

.video--deborah {
  height: 180px !important;
}