@include media-breakpoint-down(md) {
  .progress-container {
    right: 0.5rem;
    width: 95vw;
  }

  .progress-bar-text {
    top: 24%;
  }
}
