.container-card--rv {
  width: 104px;
  height: 112px;
  padding: 0 0.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.25rem;
}

.img-card--rv {
  width: 100%;
  height: 72px;
}

.card-label--rv {
  padding-top: 0.25rem;
  font-size: $font-size-base * 1;
  text-transform: capitalize;
}
