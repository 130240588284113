@include media-breakpoint-up(lg) {

  .main-container-list-do-and-dont {
    .wrapper-items--cleaning-sm {
      width: 320px;

      .clean-label {
        list-style: disc;
        text-align: left !important;
        font-size: 1.125rem;
        padding: 0;
      }
      
      .color-gray {
        color: gray;
        font-weight: 300;
      }
    }
  
    .clean-img {
      width: 120px !important;
    }
  }
}
