.item-container--cleaning-sm--general {
  position: relative;
  list-style: none;
  margin: 2rem;
  height: 330px;
  width: 26%;
  cursor: pointer;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.450);
  }

  img {
    height: 100%;
    padding: 0;
  }
}

.triangle-section-indicator {
  border: 2px solid rgb(0, 0, 0);
  position: relative;
  top: -9px;
  clip-path: polygon(50% 0%, 0 55%, 100% 55%);
}

.triangle {
  z-index: 1000;
}

.st0 {
  width: 600px !important;
}

.item-title--cleaning-sm--general p {
  height: 100px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -30%);
  font-size: 2rem;
  color: $white;
  white-space: pre-line;
  font-weight: 600;
  line-height: 2.2rem;
}

