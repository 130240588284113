@include media-breakpoint-down(md) {
  .container-table {
    margin-top: 0.5rem;
    padding: 0 1rem;
  }

  .info {
    font-size: 0.875rem;
  }

  .disclaimer {
    font-size: 0.875rem;
  }
}
