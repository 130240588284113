@include media-breakpoint-up(xl) {
  .hero {
    height: 64vh;
  }

  .videoembed {
    width: 100%;
    height: auto;
  }
}
