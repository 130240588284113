.main-container-navbar-quickship-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(153, 153, 153, 0.60);
  backdrop-filter: blur(2px);
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 0 16px;

  @media (max-width: 600px) {
    top: 0px;
  }
}

.quickship--header {
  height: 70px;
  box-sizing: border-box;
  z-index: 1;
  max-width: 1440px !important;
}

.quickship--container-logoImg {
  width: auto;
}

.header-links-container-quickship {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  a {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-family: "Gill Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: underline;
  }
}


.container-dropdown-quickship-page {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-header-nav-quickships {
  padding: 5px;
  color: #ffffff;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 153.463px;
  height: 37px;
  flex-shrink: 0;

  border-radius: 8px;
  background: #000;


  color: #FFF;
  text-align: center;
  font-family: "Gill Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  p {
    margin: 0;
  }
}

.dropdown-list-quickship-page {
  position: absolute;
  top: 100%;
  left: -10%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 3px 46px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 120%;
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  a {
    color: #000000;
    padding: 10px;
    cursor: pointer;
    width: 100% !important;
    // border: 1px solid blue;
    text-decoration: none;
    border-bottom: 1px solid rgba(153, 153, 153, 0.20);

    &:hover {
      background-color: #f1f1f1;
      text-decoration: none;
      color: #000000;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    left: 0;
  }
}


.nav-mobile-quickship {
  button {
    background-color: transparent;
    border: none;
    outline: none !important;
    cursor: pointer;
  }
}