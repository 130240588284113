.container-table {
  margin-top: 0.5rem;
  padding: 0 3rem;
}

.info {
  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: $font-weight-light;
}

.disclaimer {
  margin: 0;
  font-family: $font-family-base;
  font-size: 0.75rem;
  font-weight: $font-weight-light;
  font-style: italic;
}
