.question-marl-icon--masiala {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -32%;
  left: 75%;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: $dim-gray;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  background-color: $platinum;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  z-index: 1;
  display: inline-block;
  width: 200px;
  padding: 1rem;
  bottom: 120%;
  left: 25%;
  visibility: visible;
}

.hover-text {
  display: block !important;
  margin-bottom: .5rem;
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $platinum transparent transparent transparent;
}
