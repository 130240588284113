@include media-breakpoint-up(md) {
  .header-main-menu {
    @include clearfix();
  }

  .main-menu-sections {
    display: none;
    margin: 0;
    @include clearfix();
    display: inline-block;

    &:last-child {
      float: right;
      position: relative;
    }
  }

  .main-menu-sections__section {
    display: inline-block;
  }

  .header-menu-button {
    height: $header-height;
    line-height: $header-height;
    margin: 0;
    padding: 0 0.5rem;
  }
}
