@include media-breakpoint-down(lg) {
  .hero {
    height: 360px;
  }

  .hero-description-container {
    margin: 0 2rem;
    margin-bottom: 3rem;
    z-index: 1;

    .description-text {
      width: 80%;
      font-size: $font-size-sm;
    }
  }

  .play-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px !important;
    height: 64px !important;
  }

  .play-icon-label {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }

  .videoembed {
    height: auto;
  }
}
