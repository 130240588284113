.main-header-cleaning-guide-marine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #042e60;

  height: 74px;
  
  .header-static {
    position: absolute;
  }
  
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header-height;
    cursor: pointer;
    width: auto;
  
    img {
      width: 64%;
    }
  }
}

