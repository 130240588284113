@include media-breakpoint-down(lg) {
  .container-rpet-logo {
    padding: 1.5rem 0;
    float: right;
  }

  .rpet-logo {
    width: 200px;
  }
}
