@include media-breakpoint-down(lg) {
  .sample-count-container-quickship {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute !important;
    transform: none;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 0;
    border-radius: 0px !important;
    height: auto;
    z-index: 999;
  }

  .sample-count-text-quickship {
    border-radius: 0 !important;
    width: 100%;
    position: fixed;
    bottom: 0;

    &:hover {
      box-shadow: 0px 0px 0px transparent;
      background-color: #026972;
    }
  }
}
