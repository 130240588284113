@include media-breakpoint-down(lg) {
  .wrapper-banner--masiala {
    height: 559px !important;
  }

  .container-banner--masiala {
    height: 559px !important;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding-bottom: 4rem;
  }

  .title-banner--masiala {
    font-size: 1.5rem;
  }

  .description-banner--masiala {
    font-size: 1rem;
  }

  .container--banner-info--masiala {
    padding: 1.5rem;
    position: relative;
    bottom: 0;
  }
}
