.container-info--sm-rv {
  padding: 0 4rem;
}

.title--sm-rv {
  margin-bottom: 2rem;
  padding: 0;
  font-weight: bold;
  color: $gray-light;
}

.subtitle--sm-rv {
  font-weight: bold;
  color: $gray-light;
}

.reference--sm-rv {
  font-weight: lighter;
  text-transform: capitalize;
}

.large-img--sm-rv {
  height: 440px;
}

.color-letter-rv {
  color: $gray-light;
}
