@include media-breakpoint-down(md) {
  .info {
    font-size: 0.875rem;
  }

  .disclaimer {
    font-size: 0.5rem;
  }

  .product--information-detail--main-container {
    padding-block: 10px;
    padding-inline: 10px;
  }
}
