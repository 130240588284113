.feature-img {
  width: 100%;
  height: 100%;
}

.application-container {
  margin: 0 1.25rem;
  text-align: center;
}

.application-img {
  width: 48px;
  height: 48px;
}

.description-feature {
  font-size: 0.75rem;
  text-transform: capitalize;
  margin: 0.5rem 0;
}
