@include media-breakpoint-down(lg) {
  .main-container--cleaning-sm {
    padding: 0 1rem;
  }

  .home-slide__content--cleaning-sm {
    height: 75%;
    display: flex;
  }

  .logo-container--cleaning-sm {
    justify-content: start;
  }

  .title--cleaning-sm {
    text-align: start;
    color: $white;
  }

  .btns-container--cleaning-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
