.wrapper--rpet {
  display: flex;
  align-items: center;
  background-size: cover;
}

.title--rpet {
  padding: 0;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: $white;
}

.container-rpet-logo {
  padding: 3rem 0;
}

.rpet-logo {
  width: 272px;
}
