@include media-breakpoint-down(md) {
  .main-header-cleaning-guide-marine{

    .header-main-menu {
      position: fixed;
      left: 0;
      width: 100%;
    }
  
    .header-main-menu--open {
      display: block;
    }
  
    .main-menu-sections {
      display: block;
      max-height: 0;
      overflow: hidden;
      background-color: #000;
      transition: all 0.3s ease-out;
    }
  
    .main-menu-sections--open {
      max-height: 16rem;
    }
  
    .header-menu-button {
      height: $header-menu-button-height;
      line-height: $header-menu-button-height;
      margin: 0.5rem 1rem;
    }

  }
}
