@include media-breakpoint-up(md) {
  .hero-links--detail {
    &:before {
      content: "";
      display: block;
      width: $hero-bg-width-md;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: $hero-links-bg;
    }
  }

  .hero-links {
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    width: 50vw;
    background-color: transparent;

    &:before {
      content: "";
      display: block;
      width: $hero-bg-width-md;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    ul {
      position: relative;
      z-index: 2;

      li {
        button {
          font-size: 11px;
        }
      }
    }
  }

  .on-top {
    background-color: #d8d8d84d;
  }
}
