@media only screen and (min-width: 319px) and (max-width: 377px) {
  .video--deborah {
    height: 190px !important;
  }
}

@media only screen and (max-width: 320px) {
  .video--deborah {
    height: 170px !important;
  }
}


