@include media-breakpoint-up(lg) {
  .wrapper-samples-details--masiala {
    padding-left: 90px;
    padding-right: 90px;
  }

  .title-samples-details--masiala {
    margin: 2rem 0rem;
  }

  .large-img--quickship {
    border-radius: 1rem;
    max-height: 358px !important;
    aspect-ratio: 15 / 11;
  }

  .btn-request-add-sample-masiala {
    max-width: 355px;
  }
}
