@include media-breakpoint-down(lg) {
  .header-main-menu {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .header-main-menu--open {
    display: block;
  }

  .header-burger {
    display: flex;
  }

  .main-menu-sections {
    display: block !important;
    float: none !important;
    max-height: 0;
    background-color: #000;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .main-menu-sections__section {
    display: block !important;
  }

  .main-menu-sections--open {
    max-height: 16rem;
  }

  .header-menu-button {
    height: $header-menu-button-height;
    line-height: $header-menu-button-height;
    margin: 0.5rem 1rem;
  }

  .menu-spradling-logo {
    width: 150px !important;
  }
}
