header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-static {
  position: absolute;
}

.header-glassmorphism {
  background: rgba($gray-light, 0.25);
  backdrop-filter: blur(0.5rem);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  cursor: pointer;

  img {
    width: 64%;
  }
}
