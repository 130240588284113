.actived-card--kizuna {
  border: 1px solid $dim-gray;
}

.img-container {
  overflow: hidden;
  width: 160px;
  height: 96px;
}

.fs-text {
  font-size: 14px;
  margin: 0;
}

.add-sample-button {
  width: fit-content;
  color: $white;
  padding: 0 0.5rem !important;
}

.bag-icon {
  position: relative;
  font-size: 1rem;
  padding: 2px;
  color: $dim-gray;
  border: 0;
  background-color: $white;

  .bag-img--bag {
    height: 18px;
    color: $dim-gray;
  }

  .bag-count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: 24%;
    right: 16%;
    background-color: $dim-gray;
    color: $white;
    border-radius: 50%;
    font-size: 8px;
  }
}

.hover-icon {
  position: absolute;
  bottom: 8%;
  right: 8%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.container-card--masiala:hover .hover-icon {
  opacity: 1;
}
